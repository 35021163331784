import { DocumentApi, EvaluationApi, MedicalApi, TemplateApi } from 'apis';
import { CompositionType, CompositionStatusType } from 'apis/medical';
import uniq from 'lodash/uniq';
import { QuestionnaireResponseType } from 'apis/evaluation';
import { createErrorMessage } from 'utils/helper';

export default function useRFS() {
  const generateRFSPackageBlob = async (requestId: string, serviceRequestId: string, referralNumber: string) => {
    const rfsBlob = await TemplateApi.getRfsDocumentById(requestId).catch((error) => {
      throw new Error(createErrorMessage('Fetching RFS Document', error));
    });

    const [questionnaireResponses, soapNotes] = await Promise.all([
      EvaluationApi.searchQuestionnaireResponseByServiceRequestId(serviceRequestId),
      MedicalApi.fetchAllSoapNote(serviceRequestId),
    ]).catch((error) => {
      throw new Error(createErrorMessage('Fetching Questionnaire Responses and SOAP Notes', error));
    });
    const approvedOrFinalSoapNotes = soapNotes
      .sort(
        (a: CompositionType, b: CompositionType) =>
          new Date(a.appointment_date).getTime() - new Date(b.appointment_date).getTime()
      )
      .map((item, index) => ({
        ...item,
        sessionNumber: index + 1,
      }))
      .filter((item) => item.status === CompositionStatusType.Approved || item.status == CompositionStatusType.Final);

    const structuredInputSessionNumbers = new Set(
      approvedOrFinalSoapNotes
        .filter((item) => {
          // Check if the SOAP note has structured subjective and objective sections
          // We will exclude corresponding evaluations from the RFS final package
          return (
            item.dynamic_subjective &&
            item.dynamic_objective &&
            item.notes.every((note) => !['subjective', 'objective'].includes(note.title) || !!note.text)
          );
        })
        .map((item) => item.sessionNumber)
    );

    const filteredQuestionnaireResponses = [];
    const sessionMap = new Set<number>();
    for (const qr of questionnaireResponses) {
      if (!sessionMap.has(qr.session_number) && !structuredInputSessionNumbers.has(qr.session_number)) {
        sessionMap.add(qr.session_number);
        filteredQuestionnaireResponses.push(qr);
      }
    }

    const appointmentIds = filteredQuestionnaireResponses
      .sort((a: QuestionnaireResponseType, b: QuestionnaireResponseType) => a.session_number - b.session_number)
      .map((qr) => qr.appointment_id);
    const uniqueAppointmentIds = uniq(appointmentIds);
    const approvedSoapNotesIds = approvedOrFinalSoapNotes.map(({ id }) => id);
    const canMerge = uniqueAppointmentIds.length > 0 || approvedSoapNotesIds.length > 0;

    let combineSoapNotesAndEvaluationsBlob;

    if (canMerge) {
      combineSoapNotesAndEvaluationsBlob = await TemplateApi.combineSoapNotesAndEvaluations(
        approvedSoapNotesIds,
        uniqueAppointmentIds
      ).catch((error) => {
        throw new Error(createErrorMessage('Combining SOAP Notes and Evaluations', error));
      });
    }

    const [uploadedRfsDoc, combinedSoapNotesAndEvaluations] = await Promise.all([
      DocumentApi.uploadDocument(
        new File([rfsBlob], `RFS_${referralNumber}.pdf`, {
          type: 'application/pdf',
        })
      ),
      ...(canMerge ? [DocumentApi.uploadDocument(combineSoapNotesAndEvaluationsBlob)] : []),
    ]).catch((error) => {
      throw new Error(createErrorMessage('Uploading RFS Documents', error));
    });

    const combinedBlob: Blob = await DocumentApi.mergeDocuments(
      {
        document_ids: [uploadedRfsDoc.id, ...(canMerge ? [combinedSoapNotesAndEvaluations.id] : [])],
        filename: 'Combined PDF',
      },
      false
    ).catch((error) => {
      throw new Error(createErrorMessage('Merging Documents', error));
    });

    return combinedBlob;
  };

  return {
    generateRFSPackageBlob,
  };
}
