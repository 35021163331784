import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import { MedicalApi } from 'apis';
import { FieldTitle, TextField } from 'components';
import React from 'react';
import { useForm, useState, useUIStore } from 'utils/hooks';
import Modal, { ModalPropType } from '../templates/Modal';
import styles from './style.module.scss';

export type RevokeReferralProps = ModalPropType & {
  serviceRequestId: string;
};

const revocationReasons = [
  'VAMC - VA cancelled referral',
  'VAMC - reassigned to another company',
  'Patient - fulfillment - gender availability (in home)',
  'Patient - fulfillment - gender availability (office)',
  'Patient - fulfillment - office availability',
  'Patient - fulfillment - in home availability',
  'Patient - unhappy with Zeel / treatment',
  'Patient - provider contracted with TPA, does not want new provider',
  'Patient - requested out of network provider / service',
  'Zeel - patient unresponsive',
  'Zeel - patient RFN',
  'Zeel - patient clinical removal',
  'Zeel - patient no show / cancellation',
  'Zeel - fulfillment - gender availability (in home)',
  'Zeel - fulfillment - gender availability (office)',
  'Zeel - fulfillment - in home availability',
  'Zeel - fulfillment - office availability',
  'Zeel - referral administration',
  'Zeel - patient availability',
  'Zeel - provider RFN no other provider available',
  'Zeel - not in service area',
  'Veteran deceased',
  'Expiration reached prior to completion',
];

export default function RevokeReferral({ serviceRequestId, onClose, scrollTop, modalProps }: RevokeReferralProps) {
  const { openAlert } = useUIStore();
  const { bind, form } = useForm();
  const [error, setError] = useState<string>();

  const onSubmit = async (values: any) => {
    const { reason } = values;

    try {
      await MedicalApi.revokeReferral(serviceRequestId, {
        reason: reason,
      });

      openAlert({ title: `The referral was revoked`, severity: 'info' });
      onClose?.(true);
    } catch (e) {
      setError('An error occured while revoking the referral');
      scrollTop();
      console.error(e);
    }
  };

  const fieldProps = {
    fullWidth: true,
    variant: 'outlined',
    InputLabelProps: {
      shrink: true,
    },
  };

  return (
    <Modal
      {...modalProps}
      alert={error}
      title='Revoke Referral'
      className={styles.base}
      headerClassName={styles.header}
      bodyClassName={styles.body}
      footerClassName={styles.footer}
      onSubmit={form.handleSubmit(onSubmit)}
      actions={[
        { label: 'Back', action: 'close' },
        { label: 'Submit for Revocation', action: 'submit' },
      ]}>
      <Grid className={styles.section} container spacing={3}>
        <Grid item xs={12}>
          <FieldTitle margin>Enter a reason for this revocation</FieldTitle>
          <TextField {...bind('reason', { required: true })} select label='Reason' {...fieldProps}>
            {revocationReasons.map((reason) => (
              <MenuItem key={reason} value={reason}>
                {reason}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid className={styles.whatHappensNext} item xs={12}>
          <p>What happens next:</p>
          <ul>
            <li>All upcoming appointments will be automatically canceled.</li>
            <li>Patient and Provider will receive notifications of any canceled appointments.</li>
            <li>Referral will be marked for copying to HSRM and marked as Revoked.</li>
          </ul>
        </Grid>
      </Grid>
    </Modal>
  );
}
