import InputAdornment from '@mui/material/InputAdornment';
import Grid from '@mui/material/Unstable_Grid2';
import { DuplicateCopyBoldIcon } from '@zeel-dev/zeel-ui';
import { ZCCApi } from 'apis';
import { TextField } from 'components';
import moment from 'moment';
import React, { useCallback, useMemo } from 'react';
import { useCopyToClipboard, useState, useUIStore } from 'utils/hooks';
import {
  ZCCPatientOutreachContactAttempt,
  ZCCPatientOutreachContactAttemptOptions,
  ZCCPatientOutreachContactOutcome,
  ZCCPatientOutreachContactOutcomeOptions,
} from '../../../apis/zcc';
import Modal, { ModalPropType } from '../templates/Modal';
import styles from './style.module.scss';

export type CopyPatientContactToHsrmProps = ModalPropType & {
  taskId: string;
  vaReferralId: string;
  attempt: string;
  outcome: string;
  timestamp: string;
};

export default function CopyPatientContactToHsrm({
  taskId,
  vaReferralId,
  attempt,
  outcome,
  timestamp,
  onClose,
  scrollTop,
  modalProps,
}: CopyPatientContactToHsrmProps) {
  const { openAlert } = useUIStore();
  const { copyToClipboard } = useCopyToClipboard();
  const [error, setError] = useState<string>();

  const onSubmit = useCallback(async () => {
    try {
      await ZCCApi.copyPatientOutreachContactToHsrm(taskId);
      openAlert({
        title: `Patient Contact Copied in HSRM`,
      });
      onClose?.(true);
    } catch (e) {
      setError(`An error occured while marking the patient contact as copied in HSRM`);
      scrollTop();
      console.error(e);
    }
  }, [taskId]);

  const fieldProps = {
    fullWidth: true,
    variant: 'outlined',
    disabled: true,
    InputLabelProps: {
      shrink: true,
    },
    InputProps: {
      style: { pointerEvents: 'none' },
      endAdornment: (
        <InputAdornment position='end'>
          <DuplicateCopyBoldIcon size={24} />
        </InputAdornment>
      ),
    },
  };

  const copy = (value = '') => {
    copyToClipboard(value);
  };

  const valToCopy = useMemo(() => {
    return `${moment(timestamp).tz('America/New_York').format('YYYY-MM-DD H:mm')} - ${
      ZCCPatientOutreachContactAttemptOptions.find(
        (o: any) => o.value === (attempt as ZCCPatientOutreachContactAttempt)
      )?.label
    }, ${
      ZCCPatientOutreachContactOutcomeOptions.find(
        (o: any) => o.value === (outcome as ZCCPatientOutreachContactOutcome)
      )?.label
    }`;
  }, [timestamp, attempt, outcome]);

  return (
    <Modal
      {...modalProps}
      alert={error}
      title={'Copy Patient Contact in HSRM'}
      className={styles.base}
      footerClassName={styles.footer}
      onSubmit={() => onSubmit()}
      actions={[
        { label: 'Back', action: 'close' },
        { label: 'Copy Patient Contact to HSRM', action: 'submit' },
      ]}>
      <Grid className={styles.section} container spacing={3}>
        <Grid xs={6} className={styles.copyWrapper} onClick={() => copy(vaReferralId)}>
          <TextField value={vaReferralId} label='Referral #' {...fieldProps} />
        </Grid>
        <Grid xs={12} className={styles.copyWrapper} onClick={() => copy(valToCopy)}>
          <TextField value={valToCopy} label='Patient Contact' {...fieldProps} />
        </Grid>
      </Grid>
    </Modal>
  );
}
