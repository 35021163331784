import { useState, useMemo } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { debounce } from '@mui/material/utils';
import { AutoCompleteSearchProps } from './AutoCompleteSearch.types';

export default function AutoCompleteSearch({
  value,
  label,
  placeholder,
  noOptionsText,
  fetchItems,
  renderAdditionalDetails,
  onChange,
}: AutoCompleteSearchProps) {
  const [inputValue, setInputValue] = useState(value || '');
  const [options, setOptions] = useState<(string | { label: string; value: any })[]>(value ? [value] : []);
  const [loading, setLoading] = useState(false);

  const fetch = useMemo(
    () =>
      debounce(async (input: string) => {
        if (!input || !fetchItems) {
          setOptions([]);
        } else {
          setLoading(true);
          try {
            const items = await fetchItems(input);
            setOptions(items);
          } catch {
            console.log('An error occurred while fetching items');
            setOptions([]);
          }
          setLoading(false);
        }
      }, 400),
    [fetchItems]
  );

  return (
    <Autocomplete
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.label)}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      value={value}
      noOptionsText={noOptionsText}
      onChange={(_event: any, newValue: string | { label: string; value: any } | null) => {
        onChange?.((typeof newValue === 'string' ? newValue : newValue?.label) || '');
      }}
      isOptionEqualToValue={(option, selectedValue) =>
        typeof option === 'object' ? option.label === selectedValue : option === selectedValue
      }
      onInputChange={(_event, newInputValue, reason) => {
        if (reason === 'input') {
          fetch(newInputValue);
        } else if (!newInputValue) {
          setOptions([]);
        }
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          fullWidth
          placeholder={placeholder}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color='inherit' size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      renderOption={(props, option) => {
        const optionValue = typeof option === 'string' ? option : option.label;
        const parts = parse(optionValue, match(optionValue, inputValue, { insideWords: true }));

        return (
          <>
            <li {...props}>
              <Grid container alignItems='center'>
                <Grid item sx={{ wordWrap: 'break-word' }}>
                  {parts.map((part, index) => (
                    <Box key={index} component='span' sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}>
                      {part.text}
                    </Box>
                  ))}
                </Grid>
                {typeof option === 'object' && renderAdditionalDetails?.(option)}
              </Grid>
            </li>
          </>
        );
      }}
    />
  );
}
