import { ServiceRequestType } from 'apis/medical';
import { FieldTitle, Icon, Table } from 'components';
import styles from './style.module.scss';
import { statusIconMap, statusLabelMap } from 'pages/Medical/sections/Referrals';
import { ZccReferralStatus } from 'apis/zcc';

import cn from 'classnames';
export type ServiceRequestListProps = {
  showTitle?: boolean;
  serviceRequests?: Array<ServiceRequestType>;
  onClick?: (referralNumber: string) => void;
};

export default function ServiceRequestList({ showTitle = true, serviceRequests, onClick }: ServiceRequestListProps) {
  const rowClicked = ({ id }: any) => {
    const serviceRequest = serviceRequests?.find((s) => s.id === id);
    serviceRequest?.referral_number && onClick?.(serviceRequest.referral_number);
  };
  const columns = [
    {
      id: 'referralNumber',
      label: 'Referral #',
      width: 200,
      renderCell: ({ row }: any) => {
        return <span className={styles.referralNumber}>{row.referralNumber}</span>;
      },
    },
    { id: 'expiration', label: 'Expiration', width: 100 },
    { id: 'appointments', label: 'Appointments', width: 130 },
    {
      id: 'status',
      label: 'Status',
      width: 100,
      renderCell: ({ row }: any) => {
        return (
          <div className={cn(styles.row, styles[row?.status?.toLowerCase()])}>
            <Icon name={statusIconMap[row?.status as ZccReferralStatus]} size={16} />
            <span>{statusLabelMap[row?.status as ZccReferralStatus] ?? row?.status}</span>
          </div>
        );
      },
    },
  ];

  const rows = (serviceRequests || []).map((serviceRequest: ServiceRequestType) => {
    const { id, referral_number, expiration_date, quantity } = serviceRequest;

    return {
      id,
      referralNumber: referral_number,
      expiration: expiration_date,
      appointments: `${quantity?.available}/${quantity?.value} remaining`,
      status: serviceRequest.status.toUpperCase(),
    };
  });
  return (
    <div>
      {showTitle && <FieldTitle icon='pad-with-star'>Linked Referrals</FieldTitle>}
      <Table
        className={styles.table}
        allowSearch={false}
        footer={false}
        columns={columns}
        rows={rows}
        autoHeight
        onRowClick={rowClicked}
      />
    </div>
  );
}
