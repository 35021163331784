import React, { FC, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { Checkbox, TextField } from 'components';
import styles from './style.module.scss';
import { AutomatedReviewSettingsContentProps } from './AutomatedReviewSettings.types';

const AutomatedReviewSettingsContent: FC<AutomatedReviewSettingsContentProps> = ({ bind, form }) => {
  const fieldProps = {
    variant: 'outlined',
    InputLabelProps: {
      shrink: true,
    },
  };

  useEffect(() => {
    return () => {
      form.setField('validatedProvider', false);
      form.setField('validatedProviderFirstNotes', false);
    };
  }, [form]);

  return (
    <div>
      <p className={styles.autoApproveTitle}>Auto-Approve Future 1st Appointment Submissions</p>
      <Grid item container xs={12}>
        <Checkbox {...bind('validatedProviderFirstNotes')} label='Enable Auto-Approval of 1st Appointment SOAP Notes' />
      </Grid>
      {form.values.validatedProviderFirstNotes && (
        <>
          <p className={styles.manualReviewTitle}>Manual Review Every</p>
          <Grid item container xs={12}>
            <TextField
              {...bind('firstNoteAutomatedReviewThreshold', {
                validate: (val: any) => {
                  if (!form.values.validatedProviderFirstNotes) {
                    return null;
                  }
                  if (val === undefined || val === null || val === '') {
                    return 'This field is required';
                  }
                  if (Number(val) <= 0) return 'Cannot be lower than 0';
                  return null;
                },
              })}
              {...fieldProps}
              className={styles.threshold}
              type='number'
              margin='none'
            />
            <span className={styles.submissionLabel}>submissions</span>
          </Grid>
        </>
      )}
      <p className={styles.hint}>
        {form.values.validatedProviderFirstNotes
          ? // eslint-disable-next-line max-len
            `1 out of every ${form.values.firstNoteAutomatedReviewThreshold} submissions from this provider will go through medical review. All other submissions will be automatically approved.`
          : 'Every submission from this provider will go through medical review.'}
      </p>
      <p className={styles.autoApproveTitle}>Auto-Approve Future 2nd+ Appointment Submissions</p>
      <Grid item container xs={12}>
        <Checkbox {...bind('validatedProvider')} label='Enable Auto-Approval of 2nd+ Appointment SOAP Notes' />
      </Grid>
      {form.values.validatedProvider && (
        <>
          <p className={styles.manualReviewTitle}>Manual Review Every</p>
          <Grid item container xs={12}>
            <TextField
              {...bind('automatedReviewThreshold', {
                validate: (val: any) => {
                  if (!form.values.validatedProvider) {
                    return null;
                  }
                  if (val === undefined || val === null || val === '') {
                    return 'This field is required';
                  }
                  if (Number(val) <= 0) return 'Cannot be lower than 0';
                  return null;
                },
              })}
              {...fieldProps}
              className={styles.threshold}
              type='number'
              margin='none'
            />
            <span className={styles.submissionLabel}>submissions</span>
          </Grid>
        </>
      )}
      <p className={styles.hint}>
        {form.values.validatedProvider
          ? // eslint-disable-next-line max-len
            `1 out of every ${form.values.automatedReviewThreshold} submissions from this provider will go through medical review. All other submissions will be automatically approved.`
          : 'Every submission from this provider will go through medical review.'}
      </p>
    </div>
  );
};

export default AutomatedReviewSettingsContent;
