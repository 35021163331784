import React, { useCallback, useMemo } from 'react';
import isEqual from 'lodash/isEqual';
import { useCopyToClipboard, useFetchFacilities, useIsTestDataCreator, useState, useZccService } from 'utils/hooks';
import { ZCCApi } from 'apis';
import { AppointmentStageType } from 'apis/medical';
import { ZccApiParameters, ZccAppointmentType, ZCCSortByParameters } from 'apis/zcc';
import { SectionLayout, Table, FilterButtonV3, Icon, Tooltip } from 'components';
import Drawer from '@mui/material/Drawer';
import AppointmentSidePanel from 'components/sidePanels/Appointment';
import LinearProgress from '@mui/material/LinearProgress';
import styles from './style.module.scss';
import cn from 'classnames';
import Iframe from 'react-iframe';
import { c } from 'utils';
import { useAuth } from 'utils/hooks';
import { getCalendarTime, getLocalTimezone, joinString } from 'utils/helper';
import capitalize from 'lodash/capitalize';
import { DuplicateCopyBoldIcon } from '@zeel-dev/zeel-ui';
import { FilterType } from 'components/FilterButtonV3/FilterButtonV3.types';
import moment from 'moment-timezone';

const defaultFilters = {
  test_data: false,
};

export default function Appointments() {
  const isTestDataCreator = useIsTestDataCreator();

  const service = useZccService<ZccAppointmentType>(ZCCApi.fetchAppointmentItems, {
    initialParameters: {
      filters: defaultFilters,
    },
    paramTypes: { open_appointments: 'boolean', test_data: 'boolean' },
    presets: [
      {
        id: 'open_appointments',
        label: 'Open Appointments',
        key: 'og_stage',
        value: [
          AppointmentStageType.Blasting,
          AppointmentStageType.BlastingSpecific,
          AppointmentStageType.PendingReschedule,
          AppointmentStageType.PendingNoblast,
          AppointmentStageType.BlastingPriority,
        ],
      },
    ],
    transformRequest: (params: ZccApiParameters) => {
      const newParams = { ...params };
      // transform soap_note_status to soap_submitted and soap_approved
      if (newParams.filters?.['soap_note_status']) {
        if (newParams.filters['soap_note_status'] === 'not_submitted') {
          newParams.filters['soap_submitted'] = false;
          newParams.filters['soap_approved'] = false;
        } else if (newParams.filters['soap_note_status'] === 'submitted_not_approved') {
          newParams.filters['soap_submitted'] = true;
          newParams.filters['soap_approved'] = false;
        }
        delete newParams.filters?.['soap_note_status'];
      }
      if ((!isTestDataCreator && params.filters) || params.filters?.test_data === false) {
        delete params.filters.test_data;
      }

      return newParams;
    },
  });
  const { token } = useAuth();
  const { copyToClipboard } = useCopyToClipboard();

  const [open, setOpen] = useState<boolean>();
  const [activeRowData, setActiveRowData] = useState<any>(null);
  const [highlightedRowId, setHighlightedRowId] = useState<string | null | undefined>();
  const [openPatientOGMemberId, setOpenPatientOGMemberId] = useState<string>();
  const [openOGAppointmentId, setOpenOGAppointmentId] = useState<string>();
  const [loadingIframe, setLoadingIframe] = useState(false);
  const { loading: loadingFacilities, facilities } = useFetchFacilities();

  const rowClicked = (row: any) => {
    const { id } = row || {};
    if (open && activeRowData && id === activeRowData?.id) setOpen(false);
    else {
      setOpen(true);
      setActiveRowData(row);
    }
  };

  const sortChangeHandler = (sortModel: any) => {
    const sortBy: ZCCSortByParameters = {};
    sortModel?.forEach(({ field, sort }: any) => {
      sortBy[field] = sort;
    });
    if (!isEqual(service.parameters.sortBy, sortBy)) {
      service.setSortBy(sortBy);
    }
  };

  const highlightRow = (rowId: string) => {
    setHighlightedRowId(rowId);
    setTimeout(() => setHighlightedRowId(null), 5000);
  };

  const columns = useMemo(
    () => [
      { id: 'patient_name', label: 'Patient Name', width: 250 },
      { id: 'va_referral_id', label: 'Referral #', width: 200 },
      {
        id: 'va_facility_name',
        label: 'VA Facility Name',
        width: 250,
        renderCell: ({ value }: any) => {
          return (
            <Tooltip title={<span className={styles.tooltip}>{value}</span>} placement='top'>
              <span className={styles.ellipsisText}>{value}</span>
            </Tooltip>
          );
        },
      },
      {
        id: 'entity_id',
        label: 'OG Appt ID',
        width: 100,
      },
      {
        id: 'date',
        label: 'Appt Date/Time',
        width: 210,
        getter: ({ value, row }: any) => {
          return value
            ? moment(value)
                .tz(row.timezone || getLocalTimezone())
                .format('MMM DD, YYYY hh:mm A z')
            : '';
        },
      },
      {
        id: 'created_on',
        label: 'Created On',
        width: 210,
        getter: ({ value, row }: any) => {
          return value
            ? moment(value)
                .tz(row.timezone || getLocalTimezone())
                .format('MMM DD, YYYY hh:mm A z')
            : '';
        },
      },
      {
        id: 'practitioner_name',
        label: 'Assigned Provider',
        width: 250,
        renderCell: ({ row }: any) => {
          return (
            <div className={cn(styles.cellWrapper, styles.ellipsisText)}>
              <span>
                <Icon
                  name={row?.office_based ? 'building' : 'house'}
                  title={row?.office_based ? 'In-Office' : 'In-Home'}
                  size={16}
                />
              </span>
              <span className={styles.ellipsisText}>{row?.practitioner_name}</span>
            </div>
          );
        },
      },
      {
        id: 'og_stage',
        label: 'OG Stage',
        width: 200,
        renderCell: ({ row }: any) => {
          return (
            <div className={styles.cellWrapper}>
              <span className={styles.ellipsisText}>{row?.og_stage}</span>
              <span>
                <Icon
                  color='actionable-green'
                  name='new-window'
                  size={16}
                  onClick={(e) => {
                    e?.stopPropagation();
                    if (row?.patient_og_member_id) {
                      setOpenPatientOGMemberId(row?.patient_og_member_id);
                      setOpenOGAppointmentId(undefined);
                    } else {
                      setOpenOGAppointmentId(row?.entity_id);
                      setOpenPatientOGMemberId(undefined);
                    }
                    setLoadingIframe(true);
                  }}
                />
              </span>
            </div>
          );
        },
      },
      {
        id: 'notes',
        label: 'Notes',
        width: 250,
        cellClassName: styles.notes,
        sortable: false,
        renderCell: ({ row }: any) => {
          return (
            <div className={styles.ellipsisText}>
              {row?.main_note && <p className={cn(styles.mainNote, styles.ellipsisText)}>{row.main_note}</p>}
              {row?.action_note && <p className={cn(styles.secondLine, styles.ellipsisText)}>{row.action_note}</p>}
            </div>
          );
        },
      },
      {
        id: 'address',
        label: 'Address',
        width: 300,
        renderCell: ({ value }: any) => {
          return (
            <Tooltip
              title={
                <div className={styles.addressTooltip}>
                  <span className={styles.tooltip}>{value}</span>
                  <DuplicateCopyBoldIcon size={24} onClick={() => copyToClipboard(value)} />
                </div>
              }
              placement='top'>
              <span className={styles.ellipsisText}>{value}</span>
            </Tooltip>
          );
        },
      },
    ],
    []
  );

  const rows = useMemo(() => {
    const r = [
      ...(service.data || []).map((item: ZccAppointmentType) => {
        const {
          _id,
          actions,
          address,
          patient_name,
          date,
          created_on,
          practitioner_name,
          og_stage,
          va_referral_id,
          va_facility_name,
          og_appointment_id,
          patient_og_member_id,
          office_based,
          main_note,
          action_note,
          timezone,
        } = item;

        const mainNote = main_note
          ? `${joinString(
              [getCalendarTime(main_note.timestamp, true, 'MMM D', true), main_note.pcc_agent || ''],
              ', '
            )}: ${main_note.text || ''}`
          : '';
        const actionNote = action_note?.[0]
          ? `${joinString(
              [getCalendarTime(action_note[0].timestamp, true, 'MMM D', true), action_note[0].pcc_agent || ''],
              ', '
            )}: ${action_note[0].text || ''}`
          : '';

        return {
          id: _id,
          actions,
          date,
          created_on,
          timezone,
          address,
          patient_name,
          practitioner_name,
          og_stage,
          va_referral_id,
          va_facility_name,
          entity_id: og_appointment_id,
          patient_og_member_id,
          office_based,
          data: item,
          main_note: mainNote,
          action_note: actionNote,
        };
      }),
    ];
    return r;
  }, [service.data]);

  const sideContent = (
    <AppointmentSidePanel
      open={open}
      ogAppointmentId={activeRowData?.entity_id}
      ngAppointmentId={activeRowData?.id}
      showCustomActions
      onClose={() => setOpen(false)}
      onChange={() => {
        highlightRow(activeRowData?.id);
        service.refresh();
      }}
    />
  );

  const accessToken = useMemo(() => token?.access?.replace('Bearer', '')?.trim() || '', [token?.access]);

  const cmsUrl = useMemo(() => {
    let url = `${process.env.REACT_APP_OG_API}/cms/${c.SERVICES.ID.MEDICAL}/appointment`;
    if (openPatientOGMemberId) {
      url += `/listing/index`;
    } else if (openOGAppointmentId) {
      url += `/listing/one/${openOGAppointmentId}`;
    }
    // Hide header, chat, and footer
    url += `?_header=0&_chat=0&_footer=0&action_by_service=ZCC&bearer_token=${accessToken}`;
    if (openPatientOGMemberId) {
      url += `&member_id=${openPatientOGMemberId}`;
    }
    return url;
  }, [openOGAppointmentId, openPatientOGMemberId, accessToken]);

  const onCloseDrawer = useCallback(() => {
    setOpenOGAppointmentId(undefined);
    setOpenPatientOGMemberId(undefined);
  }, []);

  return (
    <>
      <SectionLayout alert={service.error} title='Appointments' sideContent={sideContent}>
        <div className={styles.tableHeader}>
          <FilterButtonV3
            initialValue={service.parameters.filters}
            defaultFilters={defaultFilters}
            onChange={(newFilters: any) => {
              service.setFilters(newFilters);
            }}
            searchTestDataOnly={isTestDataCreator && service.parameters.filters?.test_data}
            presets={[
              {
                id: 'open_appointments',
                label: 'Open Appointments',
              },
            ]}
            searches={[
              {
                id: 'patient_name',
                label: 'Patient Name',
                type: 'auto-complete-search',
                fetchItems: ZCCApi.searchPatient,
                renderAdditionalDetails: (option) => {
                  return (
                    <>
                      <div className={styles.patientDetails}>
                        <span>DOB</span>:{' '}
                        {option.value?.birth_date ? moment(option.value.birth_date).format('MM/DD/YYYY') : 'N/A'}
                      </div>
                      <div className={styles.patientDetails}>
                        <span>Email</span>: {option.value?.email || 'N/A'}
                      </div>
                    </>
                  );
                },
              },
              { id: 'va_referral_id', label: 'Referral #', type: 'text' },
              {
                id: 'practitioner_name',
                label: 'Assigned Provider',
                type: 'auto-complete-search',
                fetchItems: ZCCApi.searchPractitionerName,
              },
            ]}
            filters={[
              {
                id: 'og_stage',
                label: 'OG Stage',
                type: 'select-multiple',
                options: Object.values(AppointmentStageType).map((value) => ({
                  label: value.split('-').map(capitalize).join(' '),
                  value,
                })),
              },
              {
                id: 'og_appointment_id',
                label: 'OG Appt ID',
                type: 'text',
              },
              {
                id: 'soap_note_status',
                label: 'SOAP Note status',
                type: 'select',
                options: [
                  { label: 'All', value: '' },
                  { label: 'Not Submitted', value: 'not_submitted' },
                  { label: 'Submitted But Not Approved', value: 'submitted_not_approved' },
                ],
              },
              {
                id: 'va_facility_name',
                label: 'VAMC',
                placeholder: 'Search VAMC',
                type: 'auto-complete-multiple',
                maxSelections: 10,
                maxSelectionsHelperText: 'Maximum VAMC selections reached.',
                options: facilities,
                loading: loadingFacilities,
              },
              ...(isTestDataCreator
                ? [
                    {
                      id: 'test_data',
                      label: 'Test Data',
                      type: 'segments',
                      permanent: true,
                      options: [
                        { label: 'Yes', value: true },
                        { label: 'No', value: false },
                      ],
                    } as FilterType,
                  ]
                : []),
            ]}
          />
        </div>
        <Table
          className={styles.table}
          loading={service.loading}
          name={`${rows.length} Appointments`}
          tableCellClassName={styles.tableCellClassName}
          columns={columns}
          rows={rows}
          rowHeight={76}
          onRowClick={(row: any) => rowClicked(row)}
          activeRowId={open ? activeRowData?.id : undefined}
          highlightedRowId={highlightedRowId}
          page={service.parameters.page}
          pageSize={service.parameters.pageSize}
          serverPagination
          serverRowCount={service.total}
          onPageChange={(page: any) => service.setPage(page)}
          onPageSizeChange={(pageSize: any) => service.setPageSize(pageSize)}
          rowsPerPageOptions={[50]}
          sortModel={service.sortModel}
          onSortModelChange={sortChangeHandler}
        />
      </SectionLayout>
      <Drawer
        anchor={'bottom'}
        open={!!openPatientOGMemberId || !!openOGAppointmentId}
        onClose={() => onCloseDrawer()}
        PaperProps={{ sx: { width: '95%', margin: 'auto', borderRadius: 8 } }}>
        <div>
          {loadingIframe && <LinearProgress className={styles.load} />}
          <Icon className={styles.drawerClose} name='x' size={24} onClick={() => onCloseDrawer()} />
          <Iframe
            url={cmsUrl}
            id={openPatientOGMemberId || openOGAppointmentId}
            width='100%'
            height='500'
            overflow='auto'
            display='block'
            onLoad={() => setLoadingIframe(false)}
          />
        </div>
      </Drawer>
    </>
  );
}
