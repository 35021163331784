export type PatientMatchedAppointmentType = {
  id: string;
  ogAppointmentId: string;
  ogStage: string;
  officeBasedReferral: boolean;
  providerName: string;
  patientName: string;
  dateTime: string;
  dateObj: Date;
  status: string;
};

export enum PractitionerActions {
  ApproveValidatedProvider = 'approve_validated_provider',
  ApproveValidatedProviderFirstNotes = 'approve_validated_provider_first_notes',
  UnApproveValidatedProvider = 'unapprove_validated_provider',
  UnApproveValidatedProviderFirstNotes = 'unapprove_validated_provider_first_notes',
  RemoveFromNetwork = 'remove_from_network',
}
