import { useState, useEffect } from 'react';
import { MedicalApi, PractitionerApi } from 'apis';
import {
  CompositionType,
  AppointmentType,
  PatientType,
  ServiceRequestType,
  CompositionStatusType,
  FacilityType,
} from 'apis/medical';
import { PractitionerType } from 'apis/practitioner';

export default function useZccSoapNotesQuery({
  noteId,
  needPractitioner = true,
  needFacility = false,
  needCompare = true,
  onPractitionerSuccess,
  onError,
}: {
  noteId: string;
  needPractitioner?: boolean;
  needFacility?: boolean;
  needCompare?: boolean;
  onPractitionerSuccess?: (
    validatedProvider: boolean,
    validatedProviderFirstNotes: boolean,
    automatedReviewThreshold: number,
    firstNoteAutomatedReviewThreshold: number
  ) => void;
  onError?: (error: string) => void;
}) {
  const [loading, setLoading] = useState(true);
  const [soapNotes, setSoapNotes] = useState<CompositionType>();
  const [compareNotes, setCompareNotes] = useState<{
    current_version: CompositionType;
    previous_version: CompositionType;
  }>();
  const [serviceRequest, setServiceRequest] = useState<ServiceRequestType>();
  const [appointment, setAppointment] = useState<AppointmentType>();
  const [patient, setPatient] = useState<PatientType>();
  const [hasExtensionRequest, setHasExtensionRequest] = useState(false);
  const [practitioner, setPractitioner] = useState<PractitionerType>();
  const [reviewedBy, setReviewedBy] = useState<string>();
  const [facility, setFacility] = useState<FacilityType>();

  useEffect(() => {
    async function fetchData() {
      // fetch request
      setLoading(true);
      onError?.('');
      try {
        const notes = await MedicalApi.fetchSoapNote(noteId);

        const fetchPromises = [];
        fetchPromises.push(MedicalApi.fetchProcedure(notes?.procedure_id));
        if (needCompare && [CompositionStatusType.Rejected, CompositionStatusType.Submitted].includes(notes.status)) {
          fetchPromises.push(
            (async () => {
              const _compareNotes = await MedicalApi.fetchPreviousVersionSoapNote(noteId);
              setCompareNotes(_compareNotes);
            })()
          );
        } else {
          setCompareNotes(undefined);
        }
        if (notes.reviewed_by) {
          setReviewedBy(notes.reviewed_by || '');
        } else {
          setReviewedBy(undefined);
        }

        const [procedure] = await Promise.all(fetchPromises);

        const appointmentId = procedure?.identifier?.find((x: any) => x?.system?.includes('appointment_id'))?.value;
        const serviceReqId = (
          procedure?.based_on?.find((x: any) => x?.type === 'ServiceRequest')?.reference?.split('/') || []
        )
          .slice(-1)
          .pop();
        const patientId = ((procedure?.subject?.reference || '').split('/') || []).slice(-1).pop();

        const [sr, /*ogAppt,*/ pat, appt] = await Promise.all([
          MedicalApi.fetchServiceRequest(serviceReqId),
          // MedicalApi.fetchOGAppointment(ogAppointmentId),
          MedicalApi.fetchPatient(patientId),
          ...(needPractitioner ? [MedicalApi.fetchAppointment(appointmentId)] : []),
        ]);

        if (needPractitioner && appt?.practitioner_id) {
          const _practitioner = await PractitionerApi.fetchPractitionerByZeelId(appt.practitioner_id);
          setPractitioner(_practitioner);
          if (_practitioner && onPractitionerSuccess) {
            onPractitionerSuccess(
              _practitioner.validated_provider,
              _practitioner.validated_provider_first_notes,
              _practitioner.automated_review_threshold,
              _practitioner.first_note_automated_review_threshold
            );
          }
        } else {
          setPractitioner(undefined);
        }

        const patientSr = pat?.service_requests?.find((s: any) => s?.id === serviceReqId);

        if (patientSr?.va_facility_id && needFacility) {
          const _facility = await MedicalApi.fetchFacility(patientSr.va_facility_id);
          setFacility(_facility);
        }

        setSoapNotes(notes);
        setServiceRequest(patientSr);
        setAppointment(appt);
        setPatient(pat);
        setHasExtensionRequest(sr.hasExtensionRequest);
      } catch (e) {
        onError?.('An error occurred while fetching the note');
        setSoapNotes(undefined);
        setServiceRequest(undefined);
        setAppointment(undefined);
        setPatient(undefined);
        setHasExtensionRequest(false);
      }
      setLoading(false);
    }
    fetchData();
  }, [noteId]);

  return {
    loading,
    soapNotes,
    compareNotes,
    serviceRequest,
    appointment,
    patient,
    hasExtensionRequest,
    practitioner,
    reviewedBy,
    facility,
  };
}
