import { useState, useEffect } from 'react';
import Modal from '../templates/Modal';
import { useForm, useUIStore } from 'utils/hooks';
import { PractitionerApi } from 'apis';
import { AutomatedReviewSettingsProps } from './AutomatedReviewSettings.types';

import styles from './style.module.scss';
import AutomatedReviewSettingsContent from './AutomatedReviewSettingsContent';

export default function AutomatedReviewSettings({
  practitionerId,
  practitionerName,
  automatedReviewThreshold,
  firstNoteAutomatedReviewThreshold,
  needFetchAutomatedReviewThreshold,
  validated,
  validatedFirstNotes,
  scrollTop,
  onClose,
  modalProps,
}: AutomatedReviewSettingsProps) {
  const { openAlert } = useUIStore();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();
  const { bind, form } = useForm({
    validatedProvider: validated,
    validatedProviderFirstNotes: validatedFirstNotes,
    automatedReviewThreshold: needFetchAutomatedReviewThreshold ? undefined : automatedReviewThreshold || 5,
    firstNoteAutomatedReviewThreshold: needFetchAutomatedReviewThreshold
      ? undefined
      : firstNoteAutomatedReviewThreshold || 5,
  });

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        const practitioner = await PractitionerApi.getPractitioner(practitionerId);
        form.setField('automatedReviewThreshold', practitioner.automated_review_threshold || 5);
        form.setField('firstNoteAutomatedReviewThreshold', practitioner.first_note_automated_review_threshold || 5);
        setLoading(false);
      } catch {
        setLoading(false);
        setError('An error occurred while fetching the practitioner');
      }
    }
    if (needFetchAutomatedReviewThreshold) {
      fetchData();
    }
  }, [practitionerId, needFetchAutomatedReviewThreshold]);

  const handleSubmit = async (values: any) => {
    try {
      const newPractitioner = await PractitionerApi.updatePractitioner(practitionerId, {
        validated_provider: values.validatedProvider,
        validated_provider_first_notes: values.validatedProviderFirstNotes,
        ...(values.validatedProvider ? { automated_review_threshold: +values.automatedReviewThreshold } : {}),
        ...(values.validatedProviderFirstNotes
          ? { first_note_automated_review_threshold: +values.firstNoteAutomatedReviewThreshold }
          : {}),
      });
      openAlert({
        title: `${practitionerName} ${
          values.validatedProviderFirstNotes ? 'Approved' : 'Unapproved'
        } for 1st Appointment Automated Review, ${
          values.validatedProvider ? 'Approved' : 'Unapproved'
        } for 2nd+ Appointment Automated Review`,
      });
      onClose?.({
        automatedReviewThreshold: newPractitioner.validated_provider
          ? +newPractitioner.automated_review_threshold
          : undefined,
        validated: newPractitioner.validated_provider,
        validatedFirstNotes: newPractitioner.validated_provider_first_notes,
      });
    } catch (e) {
      setError('An error occurred while updating the practitioner');
      scrollTop();
      console.error(e);
    }
  };

  return (
    <Modal
      {...modalProps}
      alert={error}
      loading={loading}
      title='Automated Review Settings'
      className={styles.base}
      headerClassName={styles.header}
      bodyClassName={styles.body}
      footerClassName={styles.footer}
      onSubmit={form.handleSubmit(handleSubmit)}
      actions={[
        { label: 'Back', action: 'close' },
        {
          label: 'Save',
          action: 'submit',
        },
      ]}>
      <AutomatedReviewSettingsContent form={form} bind={bind} />
    </Modal>
  );
}
