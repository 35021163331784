import React, { useMemo } from 'react';
import cn from 'classnames';
import isEqual from 'lodash/isEqual';
import { useFetchFacilities, useIsTestDataCreator, useState, useUIStore, useZccService } from 'utils/hooks';
import { ZCCApi } from 'apis';
import { ZccMedicalReviewType, ZCCSortByParameters } from 'apis/zcc';
import { SectionLayout, Table, Button, Tooltip, Icon, FilterButtonV3 } from 'components';
import AppointmentSidePanel from 'components/sidePanels/Appointment';
import NoteSidePanel from 'components/sidePanels/Note';
import styles from './style.module.scss';
import { CompositionStatusType } from 'apis/medical';
import moment from 'moment';
import { FilterType } from 'components/FilterButtonV3/FilterButtonV3.types';

enum MedicalTaskActions {
  SoapNoteReview = 'review_soap',
  RfsReview = 'review_rfs',
  FlaggedAppointmentReview = 'review_medical_flag',
}

enum MedicalReviewTypes {
  SoapNote = 'SOAP REVIEW',
  Appointment = 'MEDICAL REVIEW',
  Request = 'RFS',
}

const reviewTypeLabel = {
  [MedicalReviewTypes.SoapNote]: 'SOAP',
  [MedicalReviewTypes.Request]: 'RFS',
  [MedicalReviewTypes.Appointment]: 'Medical',
};

const defaultFilters = {
  test_data: false,
};

export default function MedicalReviewV2() {
  const isTestDataCreator = useIsTestDataCreator();

  const service = useZccService<ZccMedicalReviewType>(ZCCApi.fetchMedicalReviewItems, {
    initialParameters: {
      sortBy: {
        last_submitted_date: 'desc',
      },
      filters: defaultFilters,
    },
    paramTypes: {
      test_data: 'boolean',
    },
    transformRequest: (params) => {
      if ((!isTestDataCreator && params.filters) || params.filters?.test_data === false) {
        delete params.filters.test_data;
      }
      return params;
    },
  });

  const [open, setOpen] = useState<boolean>(false);
  const [activeRowData, setActiveRowData] = useState<any>(null);
  const [highlightedRowId, setHighlightedRowId] = useState<string | null | undefined>();
  const { loading: loadingFacilities, facilities } = useFetchFacilities();
  const { openModal } = useUIStore();

  const rowClicked = (row: any) => {
    const { id } = row || {};
    if (open && activeRowData && id === activeRowData?.id) setOpen(false);
    else {
      setOpen(true);
      setActiveRowData(row);
    }
  };

  const sortChangeHandler = (sortModel: any) => {
    const sortBy: ZCCSortByParameters = {};
    sortModel?.forEach(({ field, sort }: any) => {
      sortBy[field] = sort;
    });
    if (!isEqual(service.parameters.sortBy, sortBy)) {
      service.setSortBy(sortBy);
    }
  };

  const highlightRow = (rowId?: string) => {
    if (!rowId) {
      return;
    }
    setHighlightedRowId(rowId);
    setTimeout(() => setHighlightedRowId(null), 5000);
  };

  // Adding appointments and notes
  const rows = useMemo(() => {
    const r = [
      ...(service.data || []).map((item: ZccMedicalReviewType, index: number) => {
        const {
          _id,
          og_appointment_id, // used for appt flagged for review (until moved to NG)
          patient_name,
          submitted_by,
          status,
          actions,
          review_type,
          session,
          referral,
          treatment_date,
          first_submitted_date,
          last_submitted_date,
          submission_count,
          va_facility_name,
        } = item;

        return {
          _id,
          id: index.toString(),
          entity_id: _id.toString(),
          og_appointment_id,
          patient_name,
          submitted_by,
          review_type,
          va_facility_name,
          session,
          referral,
          status,
          treatment_date,
          first_submitted_date,
          last_submitted_date,
          submission_count,
          actions,
          data: item,
        };
      }),
    ];
    return r;
  }, [service.data]);

  // Actions
  const openMedicalReviewModal = (appointmentId: string) => {
    if (appointmentId) {
      openModal({
        id: 'appointment-medical-review',
        props: { appointmentId },
        callback: (response: any) => {
          const previousRow = rows?.find((row: any) => row.entity_id === appointmentId);
          highlightRow(previousRow?.id);
          if (response) {
            service.refresh();
          }
        },
      });
    } else {
      alert(`OG Appointment ID is missing for this record, which prevents from opening the review modal.`);
    }
  };

  const openSoapReviewModal = (compositionId: string) => {
    if (compositionId) {
      openModal({
        id: 'provider-notes-review',
        props: { noteId: compositionId },
        callback: (response: any) => {
          const previousRow = rows?.find((row: any) => row.entity_id === compositionId);
          highlightRow(previousRow?.id);
          if (response) {
            service.refresh();
          }
        },
      });
    } else {
      alert(`Composition ID is missing for this record, which prevents from opening the review modal.`);
    }
  };

  const openReadOnlySoapNotesModal = (compositionId: string, status: CompositionStatusType) => {
    if (compositionId) {
      openModal({
        id: 'read-only-soap-notes',
        props: { noteId: compositionId, status },
      });
    } else {
      alert(`Composition ID is missing for this record, which prevents from opening the review modal.`);
    }
  };

  const openRFSReviewModal = (requestId: string) => {
    setOpen(false);
    if (requestId) {
      openModal({
        id: 'rfs-review',
        props: { requestId },
        callback: (response: any) => {
          const previousRow = rows?.find((row: any) => row.entityId === requestId);
          highlightRow(previousRow?.id);
          if (response) {
            service.refresh();
          }
        },
      });
    } else {
      alert(`Request ID is missing for this record, which prevents from opening the review modal.`);
    }
  };

  const onNoteAction = (action: string, data?: any) => {
    if (action === 'review') {
      openSoapReviewModal(data?.id);
    }
  };

  const columns = useMemo(
    () => [
      {
        id: 'patient_name',
        label: 'Patient Name',
        width: 210,
        renderCell: ({ row }: any) => {
          return (
            <div className={styles.cell}>
              <span className={cn(styles.patientName, styles.ellipsisText)}>{row?.patient_name}</span>
              <Tooltip title={<p className={styles.tooltip}>Name: {row?.patient_name}</p>} placement='top'>
                <span>
                  <Icon name='information' size={16} />
                </span>
              </Tooltip>
            </div>
          );
        },
      },
      {
        id: 'referral',
        label: 'Referral #',
        width: 180,
        renderCell: ({ row, value }: any) => {
          return (
            <div className={cn(styles.multilineCell)}>
              <span>{value}</span>
              <span>Session {row?.session}</span>
            </div>
          );
        },
      },
      {
        id: 'review_type',
        label: 'Review Type',
        width: 180,
        renderCell: ({ row, value }: any) => {
          return (
            <div className={cn(styles.multilineCell)}>
              <span>
                {reviewTypeLabel[value as MedicalReviewTypes]}
                {row?.submission_count ? ` #${row?.submission_count}` : ''}
              </span>
              <span>ID: {row?._id}</span>
            </div>
          );
        },
      },
      {
        id: 'va_facility_name',
        label: 'VA Facility Name',
        width: 250,
        renderCell: ({ value }: any) => {
          return (
            <Tooltip title={<span className={styles.tooltip}>{value}</span>} placement='top'>
              <span className={styles.ellipsisText}>{value}</span>
            </Tooltip>
          );
        },
      },
      { id: 'submitted_by', label: 'Submitted By', width: 200, sortable: true },
      { id: 'status', label: 'Status', width: 120, sortable: true },
      {
        id: 'treatment_date',
        label: 'Last Treatment',
        width: 160,
        sortable: true,
        renderCell: ({ row, value }: any) => {
          const date = value ? moment(value).format('MMM DD, YYYY') : '';
          return (
            <div className={styles.multilineCell}>
              <span>{date}</span>
              {row.og_appointment_id && <span>OG ID: {row.og_appointment_id}</span>}
            </div>
          );
        },
      },
      {
        id: 'first_submitted_date',
        label: 'First Submitted',
        width: 160,
        sortable: true,
        renderCell: ({ value }) => {
          if (!value) {
            return '';
          }
          const date = moment(value).format('MMM DD, YYYY');
          return (
            <div className={styles.multilineCell}>
              <span>{date}</span>
              <span></span>
            </div>
          );
        },
      },
      {
        id: 'last_submitted_date',
        label: 'Last Submitted',
        width: 160,
        sortable: true,
        renderCell: ({ value }) => {
          if (!value) {
            return '';
          }
          const date = moment(value).format('MMM DD, YYYY');
          const time = moment(value).format('h:mm A');
          return (
            <div className={styles.multilineCell}>
              <span>{date}</span>
              <span>{time}</span>
            </div>
          );
        },
      },
      {
        id: 'action',
        label: 'Next Step',
        width: 500,
        sortable: false,
        renderCell: ({ row }: any) => {
          const actionsList = [];
          if (row?.data?.actions?.includes(MedicalTaskActions.FlaggedAppointmentReview)) {
            actionsList.push(
              <Button
                state='primary'
                variant='outlined'
                onClick={(e: any) => {
                  openMedicalReviewModal(row?.og_appointment_id);
                  e.stopPropagation();
                }}>
                Review Flagged Appointment
              </Button>
            );
          }
          if (row?.data?.actions?.includes(MedicalTaskActions.SoapNoteReview)) {
            actionsList.push(
              <Button
                state='primary'
                variant='outlined'
                onClick={(e: any) => {
                  openSoapReviewModal(row?.entity_id);
                  e.stopPropagation();
                }}>
                Review SOAP{row?.submission_count ? ` #${row.submission_count}` : ''}
              </Button>
            );
          }
          if (row?.data?.actions?.includes(MedicalTaskActions.RfsReview)) {
            actionsList.push(
              <Button
                state='primary'
                variant='outlined'
                onClick={(e: any) => {
                  openRFSReviewModal(row?.entity_id);
                  e.stopPropagation();
                }}>
                Review RFS{row?.submission_count ? ` #${row.submission_count}` : ''}
              </Button>
            );
          }

          if (
            row?.data?.review_type === 'SOAP REVIEW' &&
            [CompositionStatusType.Rejected, CompositionStatusType.Approved].includes(row?.status.toLowerCase())
          ) {
            actionsList.push(
              <Button
                state='primary'
                variant='outlined'
                onClick={(e: any) => {
                  openReadOnlySoapNotesModal(row?.entity_id, row?.status.toLowerCase());
                  e.stopPropagation();
                }}>
                View SOAP
              </Button>
            );
          }
          return <div className={styles.actions}>{actionsList}</div>;
        },
      },
    ],
    []
  );

  let sideContent = null;

  // If active row is a flagged appt
  if (activeRowData?.review_type === MedicalReviewTypes.Appointment) {
    sideContent = (
      <AppointmentSidePanel
        open={open}
        onClose={() => setOpen(false)}
        ogAppointmentId={activeRowData?.og_appointment_id}
        ngAppointmentId={activeRowData?.entity_id}
      />
    );
  }
  // If active row is a soap note
  if (activeRowData?.review_type === MedicalReviewTypes.SoapNote) {
    sideContent = (
      <NoteSidePanel open={open} onClose={() => setOpen(false)} id={activeRowData?.entity_id} onAction={onNoteAction} />
    );
  }
  // We don't have a side-panel for rfs yet

  return (
    <SectionLayout alert={service.error} title='Medical Review Items' sideContent={sideContent}>
      <div className={styles.tableHeader}>
        <FilterButtonV3
          initialValue={service.parameters.filters}
          defaultFilters={defaultFilters}
          onChange={(newFilters: any) => {
            service.setFilters(newFilters);
          }}
          searchTestDataOnly={isTestDataCreator && service.parameters.filters?.test_data}
          searches={[
            {
              id: 'patient_name',
              label: 'Patient Name',
              type: 'auto-complete-search',
              fetchItems: ZCCApi.searchPatient,
              renderAdditionalDetails: (option) => {
                return (
                  <>
                    <div className={styles.patientDetails}>
                      <span>DOB</span>:{' '}
                      {option.value?.birth_date ? moment(option.value.birth_date).format('MM/DD/YYYY') : 'N/A'}
                    </div>
                    <div className={styles.patientDetails}>
                      <span>Email</span>: {option.value?.email || 'N/A'}
                    </div>
                  </>
                );
              },
            },
            { id: 'referral', label: 'Referral #', type: 'text' },
            {
              id: 'submitted_by',
              label: 'Submitted By',
              type: 'auto-complete-search',
              fetchItems: ZCCApi.searchPractitionerName,
            },
          ]}
          filters={[
            { id: '_id', label: 'ID', type: 'text' },
            { id: 'og_appointment_id', label: 'OG Appointment ID', type: 'text' },
            { id: 'session', label: 'Session', type: 'text' },
            { id: 'status', label: 'Status', type: 'text' },
            {
              id: 'va_facility_name',
              label: 'VAMC',
              placeholder: 'Search VAMC',
              type: 'auto-complete-multiple',
              maxSelections: 10,
              maxSelectionsHelperText: 'Maximum VAMC selections reached.',
              options: facilities,
              loading: loadingFacilities,
            },
            {
              id: 'actions',
              label: 'Task Type (Actionable Item)',
              type: 'select-multiple',
              options: [
                { label: 'Soap Note review', value: MedicalTaskActions.SoapNoteReview },
                { label: 'Flagged for Medical Review', value: MedicalTaskActions.FlaggedAppointmentReview },
                { label: 'RFS Review', value: MedicalTaskActions.RfsReview },
              ],
            },
            {
              id: 'review_type',
              label: 'Review Type',
              type: 'select-multiple',
              options: [
                { label: 'Soap Note', value: MedicalReviewTypes.SoapNote },
                { label: 'Flagged for Medical Review', value: MedicalReviewTypes.Appointment },
                { label: 'RFS', value: MedicalReviewTypes.Request },
              ],
            },
            ...(isTestDataCreator
              ? [
                  {
                    id: 'test_data',
                    label: 'Test Data',
                    type: 'segments',
                    permanent: true,
                    options: [
                      { label: 'Yes', value: true },
                      { label: 'No', value: false },
                    ],
                  } as FilterType,
                ]
              : []),
          ]}
        />
      </div>
      <Table
        className={styles.table}
        loading={service.loading}
        name={`${rows.length} Items Need Review`}
        tableCellClassName={styles.tableCellClassName}
        columns={columns}
        rows={rows}
        rowHeight={76}
        onRowClick={(row: any) => rowClicked(row)}
        activeRowId={open ? activeRowData?.rowId : undefined}
        highlightedRowId={highlightedRowId}
        page={service.parameters.page}
        pageSize={service.parameters.pageSize}
        serverPagination
        serverRowCount={service.total}
        onPageChange={(page: any) => service.setPage(page)}
        onPageSizeChange={(pageSize: any) => service.setPageSize(pageSize)}
        rowsPerPageOptions={[100]}
        sortModel={service.sortModel}
        onSortModelChange={sortChangeHandler}
      />
    </SectionLayout>
  );
}
